<template>
  <el-container class="shopList">
    <el-header class="box add bb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item>人员管理</el-breadcrumb-item>
        <el-breadcrumb-item v-if="user.user_type == 3"
          >职员列表</el-breadcrumb-item
        >
        <el-breadcrumb-item v-else>监管人员列表</el-breadcrumb-item>
      </el-breadcrumb>
    </el-header>
    <el-main class="p15 pt0">
      <div class="pt20" style="text-align:left;">
        <el-form :inline="true" class="demo-form-inline">
          <el-form-item>
            <el-input
              placeholder="姓名"
              v-model="page.user_name"
              clearable
              @clear="onSubmit(page.user_name)"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-select
              v-model="page.user_state"
              clearable
              placeholder="全部"
              @change="onSubmit(page.user_state)"
            >
              <el-option
                v-for="(item, index) in statusOptions"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="onSubmit"
              size="medium"
              icon="el-icon-search"
              >查询</el-button
            >
            <el-button
              type="success"
              v-if="user.user_type == 3"
              size="medium"
              @click="Add"
              icon="el-icon-plus"
              >新增职员</el-button
            >
            <el-button
              type="success"
              v-else
              size="medium"
              @click="Add"
              icon="el-icon-plus"
              >新增监管人员</el-button
            >
          </el-form-item>
        </el-form>
      </div>

      <!-- <el-row class="box search mt20">
       <el-form :inline="true" :model="page" class="demo-form-inline">
        <el-col :span="4" class="mr10">
            <el-form-item size="medium" >
         <el-input placeholder="姓名" v-model="page.user_name" clearable class="input-with-select" @clear="onSubmit(page.user_name)">
            </el-input>
        </el-form-item>
          </el-col>
          <el-col :span="4" class="mr10">
            <el-form-item size="medium">
              <el-select v-model="page.user_state" clearable placeholder="全部" @change="onSubmit(page.user_state)">
                <el-option
                  v-for="(item,index) in statusOptions"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
         <el-col :span="6" class="tl mr10">
           <el-form-item  size="medium">
          <el-button  type="primary"  @click="onSubmit" class="general_bgc general_border cfff">查询</el-button>
          <el-button type="primary" v-if="user.user_type==3" size="medium" @click="Add">新增职员</el-button>
            <el-button type="primary" v-else size="medium" @click="Add">新增监管人员</el-button>
        </el-form-item>
        </el-col>
      </el-form>
    </el-row> -->
      <div class="box list">
        <el-table border :data="tableData" style="width: 100%" id="out-table">
          <el-table-column
            type="index"
            align="center"
            width="60"
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="user_name"
            min-width="120"
            :show-overflow-tooltip="true"
            label="姓名"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            label="性别"
            min-width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span>{{ scope.row.user_sex == 1 ? "男" : "女" }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="user_mobilephone"
            min-width="120"
            :show-overflow-tooltip="true"
            label="登录账号"
            align="center"
          ></el-table-column>
          <el-table-column
            align="center"
            label="用户类型"
            min-width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span class="f12" v-if="scope.row.user_type == 2">监管人员</span>
              <span
                class="f12"
                v-else-if="scope.row.user_type == 4 && scope.row.user_tag == 1"
                >企业管理员</span
              >
              <span
                class="f12"
                v-else-if="scope.row.user_type == 4 && scope.row.user_tag == 2"
                >职员</span
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="user.user_type == 3"
            label="密切接触冷链食品"
            min-width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                class="f12"
                :class="scope.row.cold_chain_personnel === 1 ? 'red' : 'c666'"
                >{{ scope.row.cold_chain_personnel | antiStateFilter1 }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            label="状态"
            min-width="100"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                class="f12"
                :class="scope.row.user_state === 1 ? 'c67c' : 'ce6a'"
                >{{ scope.row.user_state | statusFilter }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="180"
            fixed="right"
            align="center"
          >
            <template slot-scope="scope">
              <!-- <el-button type="text" class="disib orange" size="mini" @click="goUpdate(scope.row.user_id)" >编辑</el-button> -->
              <!-- <el-button
                v-if="scope.row.user_tag == 1 && scope.row.user_type == 4"
                >---</el-button
              > -->
              <span
                style="color: #909399"
                v-if="scope.row.user_tag == 1 && scope.row.user_type == 4"
                >-</span
              >
              <el-button
                v-else
                type="text"
                class="disib"
                :class="{ red: scope.row.user_state == 1 }"
                size="mini"
                @click="changeState(scope.row)"
                >{{ scope.row.user_state | antiStateFilter }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="i-page fr disib mt20">
          <el-pagination
            background
            :page-size="10"
            layout="total,prev, pager, next"
            :total="total"
            :current-page="currentPage"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
import axios from "../../../util/api";
import { local, session } from "../../../util/util";
import { mapState } from "vuex";
export default {
  data() {
    return {
      user: "",
      page: {
        pager_offset: "0",
        pager_openset: "10",
        user_type: "",
        user_name: "",
        user_state: "",
        query_param: "",
      },
      timer: "",
      loading: false,
      statusOptions: [
        { label: "全部", value: "" },
        { label: "已启用", value: "1" },
        { label: "已禁用", value: "2" },
      ],
      currentPage: 1,
      total: 0,
      tableData: [],
      activeName: "1",
      company_id: "",
    };
  },
  filters: {
    statusFilter(status) {
      const statusMap = {
        1: "已启用",
        2: "已禁用",
      };
      return statusMap[status];
    },
    antiStateFilter(state) {
      const stateMap = {
        1: "禁用",
        2: "启用",
      };
      return stateMap[state];
    },
    antiStateFilter1(state) {
      const stateMap = {
        1: "是",
        2: "否",
      };
      return stateMap[state];
    },
  },
  computed: {
    ...mapState({
      userPage: (state) => state.user.userPage,
    }),
  },
  created() {
    this.page = Object.assign({}, this.userPage);
    this.user = local.get("user");
    this.page.user_type = this.user.user_type == 3 ? "4" : "2";
    this.page.query_param = this.user.user_type == 3 ? "" : "all_manage_user";
    this.currentPage = this.page.pager_offset / 10 + 1;
    this.init(this.page);
  },
  methods: {
    init(params) {
      axios.get("/pc/user/list", params).then((v) => {
        this.tableData = v.data.user_list;
        this.total = v.data.pager_count;
      });
    },
    // 搜索
    onSubmit() {
      this.currentPage = 1;
      this.page.pager_offset = "0";
      this.$store.dispatch("patrol/setNewPage", this.page);
      this.init(this.page);
    },
    // 分页
    currentChange(page) {
      this.currentPage = page;
      this.page.pager_offset = String((page - 1) * 10);
      this.$store.dispatch("patrol/setNewPage", this.page);
      this.init(this.page);
    },
    //新增处罚方式
    Add() {
      session.set("params", { id: "", name: "" });
      this.$router.push({ name: "UserListAdd" });
    },
    //编辑
    goUpdate(id) {
      session.set("params", { id: id, name: "" });
      this.$router.push({ name: "UserListAdd" });
    },
    //更改状态
    changeState(row) {
      let { user_id, user_state } = row;
      user_state = user_state == 1 ? 2 : 1;
      axios
        .put("/pc/user/state/update", { user_id, user_state })
        .then((response) => {
          this.init(this.page);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.shopList {
  overflow-x: hidden;
  .add {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /deep/ .el-input-group__append .el-button--primary .el-icon-search {
    color: #fff;
  }
}
</style>
